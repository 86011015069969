import html2pdf from 'html2pdf.js';

const defaultOptions = {
    margin: 0.5,
    image: { type: 'png' },
    jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    pagebreak: { mode: 'avoid-all' }
};

export const createBlobPdfFromHtml = async (ref, options) => {
    let worker = await html2pdf()
        .set(options)
        .from(ref)
        .toPdf()
        .output('blob');
    return worker;
};

export const createPDFFromHtml = async (ref, options, output) => {
    let worker = await html2pdf()
        .set(options)
        .from(ref)
        .toPdf()
        .output(output);
    return worker;
};

export const createRawBuffer = async (ref, fileName, options = { ...defaultOptions }) => {
    options.fileName = fileName;

    return html2pdf()
        .set(options)
        .from(ref)
        .outputPdf('arraybuffer');
};

export const downloadPdf = async (ref, fileName, options = { ...defaultOptions }) => {
    options.filename = fileName;

    html2pdf()
        .set(options)
        .from(ref)
        .toPdf()
        .save();
};

/**
 * Convert base 64 string into a buffer for downloading in new window
 * @param {Base64 String} buffer
 * @param {string} name
 */
export const openPdf = async (buffer, name) => {
    try {
        var byteCharacters = atob(buffer);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    } catch (error) {
        console.log(error);
    }
};
